import React from 'react';
import './App.css';
import FrontPage from './layouts/FrontPage';

function App() {
  return (
    <FrontPage></FrontPage>
  );
}
export default App;
